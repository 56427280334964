import React, { useEffect, useState } from "react";
import axios from "axios";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import { IconButton, ListItemButton, ListItemIcon } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import MenuIcon from "@mui/icons-material/Menu";
import ConstructionIcon from '@mui/icons-material/Construction';
import Box from "@mui/material/Box";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { List, ListItem, ListItemText, Drawer } from "@mui/material";
import { useTheme } from "@mui/material";

import ECBDataSourceInformer from "../../atoms/ECBDataSourceInformer/ECBDataSourceInformer";
import CookiePolicyMessage from "../../molecules/CookiePolicyMessage/CookiePolicyMessage";

import { CurrencyDisplayLayouts, UnitPolygon } from "../../../Util/PolygonUtil";
import { isMobile } from "../../../Util/DeviceUtil";
import {
    CurrencyVisualizationData,
    ExchangeRateNode,
    CurrencySourceData,
    CurrencyPoolOption,
} from "../../../types/CurrencyTypes";
import { getCurrencyExplanation } from "../../../constants/CurrencyInfo";
import {
    GridAreaSimilarColors,
    ALL_COLOR_PALETTES,
} from "../../../constants/DisplayColors";
import { DEFAULT_UNIT_POLYGON } from "../../../constants/DefaultUnitPolygon";
import {
    INITIAL_DISPLAY_CURRENCY_LIST,
    INITIAL_CENTRAL_CURRENCY,
} from "../../../constants/CurrencyInfo";
import {
    CURRENCY_RATE_DIVISION_TYPES,
    SELECTABLE_CURRENCY_DISPLAY_TYPES,
} from "../../../constants/DisplayTypes";
import useLocalStorage from "../../../hooks/useLocalStorage";
import ColorTheme from "../../../constants/ColorThemes";
import ConfirmSaveDialog from "../../molecules/ConfirmSaveDialog/ConfirmSaveDialog";
import CurrencyDisplayPanel from "../../organisms/CurrencyDisplayPanel/CurrencyDisplayPanel";
import FunctionalModules from "../../../constants/FunctionalModules";

type CurrencyManagerProps = {
    mode: ColorTheme;
    setMode: (mode: ColorTheme) => void;
    activeFunctionalModal: FunctionalModules;
};

const CURRENCY_DISPLAY_TYPE_COOKIE = "currencyDisplayTypeCookie";
const COLOR_PALETTE_COOKIE = "colorPaletteCookie";
const IS_COOKIE_POLICY_DISMISSED = "isCookiePolicyDismissed";
const CURRENCY_DISPLAY_SOURCE_STORAGE_KEY = "currencyDisplaySource";
const CURRENCY_DISPLAY_LIST_STORAGE_KEY = "currencyDisplayList";
const CURRENCY_RATE_DIVISION_TYPE_KEY = 'currencyRateDivisionType';


const useCurrencyManager = (props: CurrencyManagerProps) => {
    const selectableCurrencyDisplayTypes = SELECTABLE_CURRENCY_DISPLAY_TYPES;

    const [currencyDisplayType, setCurrencyDisplayType] =
        useLocalStorage<CurrencyDisplayLayouts>(
            CURRENCY_DISPLAY_TYPE_COOKIE,
            CurrencyDisplayLayouts.RADIAL_CENTERED
        );

    const [currencyRateDivisionTypeStored, setCurrencyRateDivisionTypeStored] = useLocalStorage<CURRENCY_RATE_DIVISION_TYPES>(
        CURRENCY_RATE_DIVISION_TYPE_KEY,
        CURRENCY_RATE_DIVISION_TYPES.MAIN_DIV_CONVERSION
    );

    const [currencyRateDivisionType, setCurrencyRateDivisionType] = useState(
        currencyRateDivisionTypeStored
    );

    const [currencyDisplaySourceStored, setCurrencyDisplaySourceStored] =
        useLocalStorage<string>(
            CURRENCY_DISPLAY_SOURCE_STORAGE_KEY,
            INITIAL_CENTRAL_CURRENCY
        );

    const [currencyDisplaySource, setCurrencyDisplaySource] = useState(
        currencyDisplaySourceStored
    );


    const [currencyDisplayDestinationsStored, setCurrencyDisplayDestinationsStored] = useLocalStorage<string[]>(
        CURRENCY_DISPLAY_LIST_STORAGE_KEY,
        INITIAL_DISPLAY_CURRENCY_LIST
    );

    const [currencyDisplayDestinations, setCurrencyDisplayDestinations] =
        useState(currencyDisplayDestinationsStored);

    const [currencyVisualizationData, setCurrencyVisualizationData] =
        useState<CurrencyVisualizationData>({
            type: "",
            sourceCurrency: { entity: currencyDisplaySource },
            destinationCurrencies: [],
            currencyRateDivisionType: currencyRateDivisionType,
            date: undefined,
        });

    const [selectableCurrencyPool, setSelectableCurrencyPool] = useState<
        CurrencyPoolOption[]
    >([]);

    const [cachedResponse, setCachedResponse] = useState(null);

    const [colorPalette, setColorPalette] = useState<string[]>(GridAreaSimilarColors);

    const theme = useTheme();
    const clonedDefaultUnitPolygon = { ...DEFAULT_UNIT_POLYGON };
    clonedDefaultUnitPolygon.fillColor = theme.palette.background.paper;
    
    clonedDefaultUnitPolygon.strokeColor = theme.palette.secondary.dark;
    const [unitPolygon, setUnitPolygon] = useState(clonedDefaultUnitPolygon);

    const [isCookiePolicyDismissed, setIsCookiePolicyDismissed] =
        useLocalStorage<boolean>(IS_COOKIE_POLICY_DISMISSED, false);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [drawerOpen, setDrawerOpen] = useState(false);

    const [activeFunctionalModule, setActiveFunctionalModule] = useState(props.activeFunctionalModal);

    const [isLoadingCurrencyVisualizationData, setIsLoadingCurrencyVisualizationData] = useState(true);

    function formatEmptyCurrencySlots(
        targetDestinationCurrencies: string[],
        currencyVisualizationData: CurrencyVisualizationData
    ) {
        for (let i = targetDestinationCurrencies.length; i < 6; i++) {
            currencyVisualizationData.destinationCurrencies.push({
                entity: "---",
                value: 0.0,
                dailyChange: 0.0,
                dailyChangeRate: 0.0,
            });
        }
    }

    function processCurrencyData(currencySourceData: CurrencySourceData) {
        const sourceDate = new Date(currencySourceData.date);
        let responseBaseCurrency = currencySourceData.main;
        let responseRateMap: { [key: string]: ExchangeRateNode } = {};
        let responseCurrencyPool: CurrencyPoolOption[] = [];
        if (Array.isArray(currencySourceData.rates)) {
            // Check if response.data.rates is an array
            currencySourceData.rates.forEach((rate) => {
                responseRateMap[rate.den] = {
                    entity: rate.den,
                    value: parseFloat(rate.rt),
                    dailyChange: parseFloat(rate.dChg),
                    dailyChangeRate: parseFloat(rate.dChgR),
                };
                responseCurrencyPool.push({
                    key: rate.den,
                    text: rate.den + " (" + getCurrencyExplanation(rate.den) + ")",
                    value: rate.den,
                });
            });
        }

        responseCurrencyPool.sort(function (a, b) {
            let textA = a.key.toUpperCase();
            let textB = b.key.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        setSelectableCurrencyPool(responseCurrencyPool);

        let targetSourceCurrency = currencyDisplaySource;
        let targetDestinationCurrencies = currencyDisplayDestinations;

        let currencyVisualizationData: CurrencyVisualizationData = {
            type: "",
            sourceCurrency: { entity: "" },
            destinationCurrencies: [],
            currencyRateDivisionType: currencyRateDivisionType,
            date: sourceDate,
        };

        

        let exchangeRateMap: { [key: string]: ExchangeRateNode } = {};

        currencyVisualizationData.type = currencyDisplayType;
        if (targetSourceCurrency === responseBaseCurrency) {
            currencyVisualizationData.sourceCurrency.entity = targetSourceCurrency;
            exchangeRateMap = responseRateMap;
        } else if (targetSourceCurrency in responseRateMap) {
            currencyVisualizationData.sourceCurrency.entity = targetSourceCurrency;

            let convertedRateMap: { [key: string]: ExchangeRateNode } = {};
            let currencyConverter = responseRateMap[targetSourceCurrency].value;
            let currencyConverterPrevious =
                responseRateMap[targetSourceCurrency].value -
                responseRateMap[targetSourceCurrency].dailyChange;
            if (Array.isArray(currencySourceData.rates)) {
                // Check if response.data.rates is an array
                currencySourceData.rates.forEach((rate) => {
                    let value = parseFloat(rate.rt) / currencyConverter;
                    let previousValue = parseFloat(rate.rt) - parseFloat(rate.dChg);
                    let previousRate = 1 / (currencyConverterPrevious / previousValue);
                    convertedRateMap[rate.den] = {
                        entity: rate.den,
                        value: value,
                        dailyChange: value - previousRate,
                        dailyChangeRate: (value - previousRate) / value,
                    };
                });
            }
            currencyVisualizationData.sourceCurrency.entity = targetSourceCurrency;

            exchangeRateMap = convertedRateMap;
        }

        targetDestinationCurrencies.forEach((value) => {
            if (value in exchangeRateMap) {
                const exchangeRateNode = exchangeRateMap[value];

                const tempEntity = exchangeRateNode.entity;
                let rateValue = exchangeRateNode.value;
                let tempDailyChange = exchangeRateNode.dailyChange;
                const tempDailyChangeRate = exchangeRateNode.dailyChangeRate;

                if (
                    currencyRateDivisionType ===
                    CURRENCY_RATE_DIVISION_TYPES.CONVERSION_DIV_MAIN
                ) {
                    const previousValue =
                        exchangeRateNode.value - exchangeRateNode.dailyChange;
                    tempDailyChange = 1 / rateValue - 1 / previousValue;
                    currencyVisualizationData.destinationCurrencies.push({
                        entity: tempEntity,
                        value: 1 / rateValue,
                        dailyChange: 1 / rateValue - 1 / previousValue,
                        dailyChangeRate:
                            (1 / rateValue - 1 / previousValue) / (1 / rateValue),
                    });
                } else if (
                    currencyRateDivisionType ===
                    CURRENCY_RATE_DIVISION_TYPES.MAIN_DIV_CONVERSION
                ) {
                    currencyVisualizationData.destinationCurrencies.push({
                        entity: tempEntity,
                        value: rateValue,
                        dailyChange: tempDailyChange,
                        dailyChangeRate: tempDailyChangeRate,
                    });
                }
            }
        });

        formatEmptyCurrencySlots(
            targetDestinationCurrencies,
            currencyVisualizationData
        );

        return currencyVisualizationData;
    }

    useEffect(() => {
        async function fetchCurrencyData() {
            setIsLoadingCurrencyVisualizationData(true);
            if (cachedResponse !== null) {
                let currencyVisualizationData = processCurrencyData(cachedResponse);
                setCurrencyVisualizationData(currencyVisualizationData);
                setIsLoadingCurrencyVisualizationData(false);
            } else {
                axios
                    .get(
                        `https://zr1bi3gvbl.execute-api.us-east-1.amazonaws.com/prod/currency`
                    )
                    .then((response) => {
                        let currencyVisualizationData = processCurrencyData(response.data);
                        setCurrencyVisualizationData(currencyVisualizationData);
                        setCachedResponse(response.data);
                    });
            }
        }

        fetchCurrencyData();
    }, [
        props,
        currencyDisplaySource,
        isLoadingCurrencyVisualizationData,
        currencyDisplayDestinations,
        currencyDisplayType,
        colorPalette,
        cachedResponse,
        currencyRateDivisionType,
        unitPolygon,
        theme,
    ]);

    useEffect(() => {
        const updatedUnitPolygon = {
            ...unitPolygon,
            fillColor: theme.palette.background.paper,
        };
        setUnitPolygon(updatedUnitPolygon);
    }, [theme]);
    return {
        currencyVisualizationData,
        isLoadingCurrencyVisualizationData,
        selectableCurrencyPool,
        currencyDisplaySource,
        setCurrencyDisplaySource,
        currencyDisplayDestinations,
        setCurrencyDisplayDestinations,
        currencyDisplayType,
        setCurrencyDisplayType,
        selectableCurrencyDisplayTypes,
        colorPalette,
        unitPolygon,
        isCookiePolicyDismissed,
        setIsCookiePolicyDismissed,
        currencyDivisionType: currencyRateDivisionType,
        setCurrencyDivisionType: setCurrencyRateDivisionType,
        setCurrencyRateDivisionTypeStored,
        mode: props.mode,
        setMode: props.setMode,
        setCurrencyDisplaySourceStored,
        isDialogOpen,
        setIsDialogOpen,
        setCurrencyDisplayDestinationsStored,
        drawerOpen,
        setDrawerOpen,
        activeFunctionalModule,
        setActiveFunctionalModule,
    };
};

const CurrencyManager: React.FC<CurrencyManagerProps> = (props) => {
    const {
        currencyVisualizationData,
        isLoadingCurrencyVisualizationData,
        selectableCurrencyPool,
        currencyDisplaySource,
        setCurrencyDisplaySource,
        currencyDisplayDestinations,
        setCurrencyDisplayDestinations,
        currencyDisplayType,
        setCurrencyDisplayType,
        selectableCurrencyDisplayTypes,
        colorPalette,
        unitPolygon,
        isCookiePolicyDismissed,
        setIsCookiePolicyDismissed,
        currencyDivisionType,
        setCurrencyDivisionType,
        setCurrencyRateDivisionTypeStored,
        mode,
        setMode,
        setCurrencyDisplaySourceStored,
        isDialogOpen,
        setIsDialogOpen,
        setCurrencyDisplayDestinationsStored,
        drawerOpen,
        setDrawerOpen,
        activeFunctionalModule,
        setActiveFunctionalModule,
    } = useCurrencyManager(props);

    const toggleTheme = () => {
        const newMode =
            mode === ColorTheme.LIGHT ? ColorTheme.DARK : ColorTheme.LIGHT;
        setMode(newMode);
    };

    const handleSaveClick = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleConfirm = () => {
        setIsDialogOpen(false);
        setCurrencyDisplaySourceStored(currencyDisplaySource);
        setCurrencyDisplayDestinationsStored(currencyDisplayDestinations);
        setCurrencyRateDivisionTypeStored(currencyDivisionType);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleModuleSelection = (module: FunctionalModules) => {
        setActiveFunctionalModule(module);
        window.history.pushState(null, '', `/${module}`);
    };

    return (
        <>
            <CssBaseline />
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar
                        variant="dense"
                        sx={{ minHeight: 48, height: 48 }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            Exchange UI
                        </Typography>

                        <IconButton onClick={toggleTheme}>
                            {mode === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box>

            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerToggle}
            >
                <List>
                    <ListItem key={"1"} disablePadding>
                        <ListItemButton onClick={() => handleModuleSelection(FunctionalModules.CURRENCY_DISPLAY_PANEL)}>
                            <ListItemIcon>
                                <AnalyticsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Currency Display Panel" />
                        </ListItemButton>
                    </ListItem>
                    
                    
                    <ListItem key={"2"} disablePadding>
                        <ListItemButton onClick={() => handleModuleSelection(FunctionalModules.CURRENCY_CONVERTER_PANEL)}>
                            <ListItemIcon>
                                <CurrencyExchangeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Currency Converter" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>

            <Box sx={{ marginBottom: 2 }} />

            {activeFunctionalModule === FunctionalModules.CURRENCY_DISPLAY_PANEL && 
                <CurrencyDisplayPanel
                    currencyDisplayType={currencyDisplayType}
                    isLoadingCurrencyVisualizationData={isLoadingCurrencyVisualizationData}
                    currencyVisualizationData={currencyVisualizationData}
                    unitPolygon={unitPolygon}
                    colorPalette={colorPalette}
                    setCurrencyDisplayType={setCurrencyDisplayType}
                    selectableCurrencyDisplayTypes={selectableCurrencyDisplayTypes}
                    setCurrencyDisplaySource={setCurrencyDisplaySource}
                    selectableCurrencyPool={selectableCurrencyPool}
                    currencyDisplaySource={currencyDisplaySource}
                    setCurrencyDisplayDestinations={setCurrencyDisplayDestinations}
                    currencyDisplayDestinations={currencyDisplayDestinations}
                    currencyDivisionType={currencyDivisionType}
                    setCurrencyDivisionType={setCurrencyDivisionType}
                    handleSaveClick={handleSaveClick}
                    isDialogOpen={isDialogOpen}
                    handleDialogClose={handleDialogClose}
                    handleConfirm={handleConfirm}
                />
            }

            {activeFunctionalModule === FunctionalModules.CURRENCY_CONVERTER_PANEL && 
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                    <Typography variant="h4" sx={{ textAlign: 'center' }}>
                        <ConstructionIcon sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                        Work in Progress
                    </Typography>
                </Box>
            }

            <ConfirmSaveDialog
                isOpen={isDialogOpen}
                onClose={handleDialogClose}
                onConfirm={handleConfirm}
            />

            <ECBDataSourceInformer />
            <CookiePolicyMessage
                isCookiePolicyDismissed={isCookiePolicyDismissed}
                setIsCookiePolicyDismissed={setIsCookiePolicyDismissed}
            />
        </>
    );
};

export default CurrencyManager;
