import { CurrencyDisplayLayouts } from "../Util/PolygonUtil";

export type CurrencyDisplayType = {
    key: CurrencyDisplayLayouts;
    text: string;
    value: CurrencyDisplayLayouts;
}

export const SELECTABLE_CURRENCY_DISPLAY_TYPES: CurrencyDisplayType[] = [
    { key: CurrencyDisplayLayouts.RADIAL_CENTERED, text: 'Hexagonal', value: CurrencyDisplayLayouts.RADIAL_CENTERED },
    { key: CurrencyDisplayLayouts.TABLE, text: 'Table', value: CurrencyDisplayLayouts.TABLE },
];

export enum CURRENCY_RATE_DIVISION_TYPES {
    MAIN_DIV_CONVERSION = "MAIN_DIV_CONV",
    CONVERSION_DIV_MAIN = "CONV_DIV_MAIN",
}