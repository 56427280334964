type Point = [number, number];
type Edges = {
    minX: number,
    minY: number,
    maxX: number,
    maxY: number
};
type Dimensions = {
    xDim: number,
    yDim: number
};
const PolygonUtils = {
    angleToRadians: (degree: number): number => {
        return degree * (Math.PI / 180);
    },
    generatePoints: (numSides: number, radius: number, centerAng: number, startAngle: number): Point[] => {
        const startAng = PolygonUtils.angleToRadians(startAngle);
        const vertex: Point[] = [];
        for (let i = 0; i < numSides; i++) {
            const ang = startAng + (i * centerAng);
            let x = (radius * Math.cos(ang));
            let y = (radius * Math.sin(ang));
            vertex.push([x, y]);
        }
        return vertex;
    },
    getEdgePoints: (vertex: Point[]): Edges => {
        const arrayColumn = (arr: Point[], n: number) => arr.map(x => x[n]);
        let xValues = arrayColumn(vertex, 0);
        let yValues = arrayColumn(vertex, 1);
        let maxX = Math.max(...xValues);
        let maxY = Math.max(...yValues);
        let minX = Math.min(...xValues);
        let minY = Math.min(...yValues);
        return {
            minX: Math.floor(minX),
            minY: Math.floor(minY),
            maxX: Math.ceil(maxX),
            maxY: Math.ceil(maxY)
        };
    },
    getDimensions: (edges: Edges): Dimensions => {
        return {
            xDim: Math.ceil(edges.maxX - edges.minX),
            yDim: Math.ceil(edges.maxY - edges.minY)
        };
    },
    getShiftedPositiveQuadrant: (points: Point[], edges: Edges): Point[] => {
        let shiftedPoints: Point[] = JSON.parse(JSON.stringify(points));
        shiftedPoints.map(pair => {
            pair[0] += -1 * edges.minX;
            pair[1] += -1 * edges.minY;
            return true;
        });
        return shiftedPoints;
    }
}


export default PolygonUtils;
export enum CurrencyDisplayLayouts {
    RADIAL_CENTERED = "RADIAL_CENTERED",
    TABLE = "TABLE",
}
export enum PolygonType {
    GRID = "GRID",
    CENTERED = "CENTERED",
}

export enum Directions {
    NORTH = "NORTH",
    SOUTH = "SOUTH",
    NORTHEAST = "NORTHEAST",
    SOUTHWEST = "SOUTHWEST",
    NORTHWEST = "NORTHWEST",
    SOUTHEAST = "SOUTHEAST",
    CENTER = "CENTER",
    EAST = "EAST",
    WEST = "WEST",
}
export const ClockwiseHexagonRegions: Directions[] = [
    Directions.NORTH,
    Directions.NORTHEAST,
    Directions.SOUTHEAST,
    Directions.SOUTH,
    Directions.SOUTHWEST,
    Directions.NORTHWEST
];

export const ClockwiseHexagonDirections: Directions[] = [
    Directions.NORTHEAST,
    Directions.EAST,
    Directions.SOUTHEAST,
    Directions.SOUTHWEST,
    Directions.WEST,
    Directions.NORTHWEST
];

export type OrientationOffset = {
    [key in Directions]?: { x: number, y: number }
};

export const getRegionalOrientations = (offsetX: number = 0, offsetY: number = 0): OrientationOffset => {
    let orientationOffset: OrientationOffset = {};
    orientationOffset[Directions.NORTH] = { x: offsetX + 1, y: offsetY - 2 };
    orientationOffset[Directions.NORTHEAST] = { x: offsetX + 2, y: offsetY - 1 };
    orientationOffset[Directions.SOUTHEAST] = { x: offsetX + 1, y: offsetY + 1 };
    orientationOffset[Directions.SOUTH] = { x: offsetX - 1, y: offsetY + 2 };
    orientationOffset[Directions.SOUTHWEST] = { x: offsetX - 2, y: offsetY + 1 };
    orientationOffset[Directions.NORTHWEST] = { x: offsetX - 1, y: offsetY - 1 };
    orientationOffset[Directions.CENTER] = { x: offsetX, y: offsetY };
    return orientationOffset;
}

export const getDirectionalOrientations = (offsetX: number = 0, offsetY: number = 0): OrientationOffset => {
    let orientationOffset: OrientationOffset = {}
    orientationOffset[Directions.NORTHEAST] = { x: offsetX + 1, y: offsetY - 1 };
    orientationOffset[Directions.EAST] = { x: offsetX + 1, y: offsetY - 0 };
    orientationOffset[Directions.SOUTHEAST] = { x: offsetX + 0, y: offsetY + 1 };
    orientationOffset[Directions.SOUTHWEST] = { x: offsetX - 1, y: offsetY + 1 };
    orientationOffset[Directions.WEST] = { x: offsetX - 1, y: offsetY + 0 };
    orientationOffset[Directions.NORTHWEST] = { x: offsetX - 0, y: offsetY - 1 };
    orientationOffset[Directions.CENTER] = { x: offsetX, y: offsetY };
    return orientationOffset;
}

export type UnitPolygon = {
    edgeOffsetRatio: number;
    startAngle: number;
    numSides: number;
    fillColor: string;
    strokeColor: string;
    text: string;
    textFontSize: number;
    innerPolygonRatio: number;
    innerFillColor: string;
    renderingOrder: number;
};