import React, { useEffect, useState } from "react";
import axios from "axios";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import { IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import CurrencyGridDisplay from "../../organisms/CurrencyGridDisplay/CurrencyGridDisplay";
import CurrencySearchSelection from "../../molecules/CurrencySearchSelection/CurrencySearchSelection";
import CurrencyMultipleSearchSelection from "../../molecules/CurrencyMultipleSearchSelection/CurrencyMultipleSearchSelection";
import CurrencyCenteredDisplay from "../../organisms/CurrencyCenteredDisplay/CurrencyCenteredDisplay";
import ECBDataSourceInformer from "../../atoms/ECBDataSourceInformer/ECBDataSourceInformer";
import HexagonalDisplayTypeMenu from "../../atoms/HexagonalDisplayTypeMenu/HexagonalDisplayTypeMenu";
import ColorPaletteSelector from "../../molecules/ColorPaletteSelector/ColorPaletteSelector";
import CookiePolicyMessage from "../../molecules/CookiePolicyMessage/CookiePolicyMessage";
import CurrencySwitchButton from "../../atoms/CurrencySwitchButton/CurrencySwitchButton";
import DateInfoHeader from "../../atoms/DateInfoHeader/DateInfoHeader";

import { CurrencyDisplayLayouts, UnitPolygon } from "../../../Util/PolygonUtil";
import { isMobile } from "../../../Util/DeviceUtil";
import {
	CurrencyVisualizationData,
	ExchangeRateNode,
	CurrencySourceData,
	CurrencyPoolOption,
} from "../../../types/CurrencyTypes";
import { getCurrencyExplanation } from "../../../constants/CurrencyInfo";
import {
	GridAreaSimilarColors,
	ALL_COLOR_PALETTES,
} from "../../../constants/DisplayColors";
import { DEFAULT_UNIT_POLYGON } from "../../../constants/DefaultUnitPolygon";
import {
	INITIAL_DISPLAY_CURRENCY_LIST,
	INITIAL_CENTRAL_CURRENCY,
} from "../../../constants/CurrencyInfo";
import {
	CURRENCY_RATE_DIVISION_TYPES,
	CurrencyDisplayType,
	SELECTABLE_CURRENCY_DISPLAY_TYPES,
} from "../../../constants/DisplayTypes";
import useLocalStorage from "../../../hooks/useLocalStorage";
import ColorTheme from "../../../constants/ColorThemes";
import ConfirmSaveDialog from "../../molecules/ConfirmSaveDialog/ConfirmSaveDialog";
import CurrencyDisplay from "../../organisms/CurrencyDisplay/CurrencyDisplay";

type CurrencyDisplayPanelProps = {
	currencyDisplayType: CurrencyDisplayLayouts;
	isLoadingCurrencyVisualizationData: boolean;
	currencyVisualizationData: CurrencyVisualizationData;
	unitPolygon: UnitPolygon;
	colorPalette: string[];
	setCurrencyDisplayType: (type: CurrencyDisplayLayouts) => void;
	selectableCurrencyDisplayTypes: CurrencyDisplayType[];
	setCurrencyDisplaySource: (source: string) => void;
	selectableCurrencyPool: CurrencyPoolOption[];
	currencyDisplaySource: string;
	setCurrencyDisplayDestinations: (destinations: string[]) => void;
	currencyDisplayDestinations: string[];
	currencyDivisionType: CURRENCY_RATE_DIVISION_TYPES;
	setCurrencyDivisionType: (type: CURRENCY_RATE_DIVISION_TYPES) => void;
	handleSaveClick: () => void;
	isDialogOpen: boolean;
	handleDialogClose: () => void;
	handleConfirm: () => void;
};

const CurrencyDisplayPanel: React.FC<CurrencyDisplayPanelProps> = ({
	currencyDisplayType,
	isLoadingCurrencyVisualizationData,
	currencyVisualizationData,
	unitPolygon,
	colorPalette,
	setCurrencyDisplayType,
	selectableCurrencyDisplayTypes,
	setCurrencyDisplaySource,
	selectableCurrencyPool,
	currencyDisplaySource,
	setCurrencyDisplayDestinations,
	currencyDisplayDestinations,
	currencyDivisionType,
	setCurrencyDivisionType,
	handleSaveClick,
	isDialogOpen,
	handleDialogClose,
	handleConfirm,
}) => {


	return (
		<>
			{!isLoadingCurrencyVisualizationData ? (
			<>
				<CurrencyDisplay
					currencyDisplayType={currencyDisplayType}
					currencyVisualizationData={currencyVisualizationData}
					unitPolygon={unitPolygon}
					colorPalette={colorPalette}
					setCurrencyDisplayType={setCurrencyDisplayType}
					selectableCurrencyDisplayTypes={selectableCurrencyDisplayTypes}
				/>

				<CurrencySearchSelection
					setValue={setCurrencyDisplaySource}
					options={selectableCurrencyPool}
					value={currencyDisplaySource}
				/>
				<CurrencyMultipleSearchSelection
					setValue={setCurrencyDisplayDestinations}
					options={selectableCurrencyPool}
					value={currencyDisplayDestinations}
				/>

				<Box
					sx={{
						width: "90%",
						margin: "0.75% auto",
					}}
				>
					<Stack direction="row" spacing={2}>
						<CurrencySwitchButton
							currencyDivisionType={currencyDivisionType}
							setCurrencyDivisionType={setCurrencyDivisionType}
						/>

						<Button variant="outlined" onClick={handleSaveClick}>
							Save
						</Button>
					</Stack>
				</Box>

				<ConfirmSaveDialog
					isOpen={isDialogOpen}
					onClose={handleDialogClose}
					onConfirm={handleConfirm}
				/>
			</>
			) : (
				<Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70vh'
                    }}
                >
                    <CircularProgress />
                </Box>
			)}
		</>
	);
};

export default CurrencyDisplayPanel;