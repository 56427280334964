import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

interface ConfirmSaveDialogProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

const ConfirmSaveDialog: React.FC<ConfirmSaveDialogProps> = ({ isOpen, onClose, onConfirm }) => {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby="confirm-dialog-title"
			aria-describedby="confirm-dialog-description"
		>
			<DialogTitle id="confirm-dialog-title">Confirm Save</DialogTitle>
			<DialogContent>
				<DialogContentText id="confirm-dialog-description">
					Are you sure you want to save the changes?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				<Button onClick={onConfirm} color="primary" autoFocus>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmSaveDialog;