import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import CurrencyManager from "./hexagon_currency_app/components/pages/CurrencyManager/CurrencyManager";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ColorTheme from './hexagon_currency_app/constants/ColorThemes';
import useLocalStorage from './hexagon_currency_app/hooks/useLocalStorage';
import FunctionalModules from './hexagon_currency_app/constants/FunctionalModules';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const MODE_KEY = 'colorThemeMode';

const App = () => {
  const [mode, setMode] = useLocalStorage<ColorTheme>(MODE_KEY, ColorTheme.LIGHT);

  const theme = createTheme({
    palette: {
      mode: mode,
    },
  });

  //add mode and setMode to the CurrencyManager component

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <React.StrictMode>
        <Router>
          <Routes>
            <Route path="/display" element={<CurrencyManager mode={mode} setMode={setMode} activeFunctionalModal={FunctionalModules.CURRENCY_DISPLAY_PANEL} />} />
            <Route path="/converter" element={<CurrencyManager mode={mode} setMode={setMode} activeFunctionalModal={FunctionalModules.CURRENCY_CONVERTER_PANEL} />} />
            <Route path="/*" element={<CurrencyManager mode={mode} setMode={setMode} activeFunctionalModal={FunctionalModules.CURRENCY_DISPLAY_PANEL} />} />
          </Routes>
        </Router>
      </React.StrictMode>
    </ThemeProvider>
  );
};

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
