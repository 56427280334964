import React from "react";
import Box from "@mui/material/Box";
import CurrencyGridDisplay from "../../organisms/CurrencyGridDisplay/CurrencyGridDisplay";
import CurrencyCenteredDisplay from "../../organisms/CurrencyCenteredDisplay/CurrencyCenteredDisplay";
import HexagonalDisplayTypeMenu from "../../atoms/HexagonalDisplayTypeMenu/HexagonalDisplayTypeMenu";
import ColorPaletteSelector from "../../molecules/ColorPaletteSelector/ColorPaletteSelector";
import DateInfoHeader from "../../atoms/DateInfoHeader/DateInfoHeader";

import { CurrencyDisplayLayouts, UnitPolygon } from "../../../Util/PolygonUtil";
import {
    CurrencyVisualizationData,
} from "../../../types/CurrencyTypes";
import {
    ALL_COLOR_PALETTES,
} from "../../../constants/DisplayColors";
import { CURRENCY_RATE_DIVISION_TYPES, CurrencyDisplayType } from "../../../constants/DisplayTypes";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface CurrencyDisplayProps {
	currencyDisplayType: CurrencyDisplayLayouts;
	currencyVisualizationData: CurrencyVisualizationData;
	unitPolygon: UnitPolygon;
	colorPalette: string[];
	setCurrencyDisplayType: (type: CurrencyDisplayLayouts) => void;
	selectableCurrencyDisplayTypes: CurrencyDisplayType[];
}

const formatValue = (value: number) => {
    if (value === 0) {
        return "";
    }
    if (Math.abs(value) >= 1e6 || Math.abs(value) < 1e-4) {
        return value.toExponential(2);
    }
    return value.toFixed(4);
};

const CurrencyDisplay: React.FC<CurrencyDisplayProps> = ({
	currencyDisplayType,
	currencyVisualizationData,
	unitPolygon,
	colorPalette,
	setCurrencyDisplayType,
	selectableCurrencyDisplayTypes,
}) => {
    
	return (
		<Box sx={{ flexGrow: 1 }}>

			{currencyDisplayType === CurrencyDisplayLayouts.RADIAL_CENTERED && (
				<CurrencyCenteredDisplay
					type={currencyDisplayType}
					currencyVisualizationData={currencyVisualizationData}
					edgeLength={7}
					defaultUnitPolygon={unitPolygon}
					colorPalette={colorPalette}
				/>
			)}

            {currencyDisplayType === CurrencyDisplayLayouts.TABLE && (
                <TableContainer 
                component={Paper}
                sx={{ width: '90%', margin: '0 auto' }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Currency</TableCell>
                                <TableCell align="right">Rate</TableCell>
                                <TableCell align="right">Daily Change</TableCell>
                                <TableCell align="right">Daily Change Rate</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currencyVisualizationData.destinationCurrencies.map((row) => (
                                <TableRow
                                    key={row.entity}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {currencyVisualizationData.currencyRateDivisionType === CURRENCY_RATE_DIVISION_TYPES.MAIN_DIV_CONVERSION ? currencyVisualizationData.sourceCurrency.entity + "/" + row.entity : row.entity + "/" + currencyVisualizationData.sourceCurrency.entity}
                                    </TableCell>
                                    <TableCell align="right" style={{ fontSize: '1.0em' }}>
                                        <strong>{formatValue(row.value)}</strong>
                                    </TableCell>
                                    <TableCell align="right" style={{ color: row.dailyChange > 0 ? 'green' : row.dailyChange < 0 ? 'red' : 'inherit' }}>
                                        <strong>{formatValue(row.dailyChange)}</strong>
                                    </TableCell>
                                    <TableCell align="right" style={{ color: row.dailyChangeRate > 0 ? 'green' : row.dailyChangeRate < 0 ? 'red' : 'inherit' }}>
                                        <strong>
                                            {row.dailyChangeRate > 0 ? '▲' : row.dailyChangeRate < 0 ? '▼' : ''} {formatValue(row.dailyChangeRate * 100)}%
                                        </strong>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<HexagonalDisplayTypeMenu
					setValue={setCurrencyDisplayType}
					options={selectableCurrencyDisplayTypes}
					value={currencyDisplayType}
				/>
			</Box>
			<DateInfoHeader date={currencyVisualizationData.date} />
		</Box>
	);
};

export default CurrencyDisplay;